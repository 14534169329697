const config = {
    appName: "seller-admin",
    env: "dev",
    //apiUrl: "http://localhost:3888/api/",
    //socketUrl: "http://localhost:3888/"
     apiUrl: "https://dev.nodeapi.fairdeal.market/api/",
     socketUrl: "https://dev.nodeapi.fairdeal.market/"
    };
    
    export default config;
