import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BsFillImageFill } from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";
import { useRecoilState, useRecoilValue } from "recoil";
import { API } from "service/api";
import { selectCatID, selectCatLevel } from "store/products/category";
import {
  productAtom,
  productsList,
  searchProductsList,
  showProductAddModal,
} from "store/products/products";
import stylee from "./ProductAdd.module.css";
import UploadImageModal from "./UploadImageModal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

interface BrandInterface {
  _id: string;
  brandCode: string;
  name: string;
  description: string;
  thumbnail: string;
  image: string[];
}
interface Tax {
  type: string;
  value: number;
}

export default function ProductAdd() {
  const [showProductModal, setShowProductModal] =
    useRecoilState(showProductAddModal);
  const [catId, setCatId] = useRecoilState(selectCatID);
  const [catLevel, setCatLevel] = useRecoilState(selectCatLevel);
  const productEditData: any = useRecoilValue(productAtom);

  const handleOpen = () => {
    setShowProductModal(true);
  };
  const handleClose = () => {
    setShowProductModal(false);
  };
  const [dummyImageProduct, setDummyImageProduct] = useState([
    "1",
    "2",
    "3",
    "4",
  ]);

  const [level1Cate, setlevel1cate] = useState([]);
  const [level2Cate, setlevel2cate] = useState([]);
  const [level3Cate, setlevel3cate] = useState([]);
  const [level1ID, setlevel1ID] = useState(productEditData || "");
  const [level2ID, setlevel2ID] = useState("");
  const [level3ID, setlevel3ID] = useState("");
  const [veg, setVeg] = useState<boolean>(true);
  const [productName, setProductName] = useState<string>("");
  const [productId, setProductId] = useState<string>("");
  const [productDescription, setProductDescription] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [sellingPrice, setSellingPrice] = useState<number>(0);
  const [costPrice, setCostPrice] = useState<number>(0);
  const [gstType, setGstType] = useState<string>("");
  let [gst, setGst] = useState<number>(0);
  let [cess, setCess] = useState<number>(0);
  const [productPhotos, setProductPhotos] = useState<File[]>([]);
  const [open, setOpen] = useState(false);
  const [brand, setBrand] = useState<BrandInterface | null>(null);
  const [skuCode, setSkuCode] = useState<string>("");
  const [eanCode, setEanCode] = useState<string>("");
  const [hsnCode, setHsnCode] = useState<string>("");
  const [quantity, setQuantity] = useState<number>(0);
  const [productType, setProductType] = useState<string>("");
  const [quantityUnit, setQuantityUnit] = useState<string>("");
  const [packOf, setPackOf] = useState<number>(0);
  const [licenseRequired, setLicenseRequired] = useState(false);
  const [licenseName, setLicenseName] = useState<string>("");
  const [form, setForm] = useState<string>("");
  const [containerType, setContainerType] = useState<string>("");
  const [countryOfOrigin, setCountryOfOrigin] = useState<string>("");
  const [mfgBy, setMfgBy] = useState<string>("");
  const [mktdBy, setMktdBy] = useState<string>("");
  const [importedBy, setImportedBy] = useState<string>("");
  const [productsRecoil, setProductsRecoil] = useRecoilState(productsList);
  const [searchProductsRecoil, setSearchProductsRecoil] =
    useRecoilState(searchProductsList);
  const [allBrands, setAllBrands] = useState<BrandInterface[]>([]);
  const [filteredBrands, setFilteredBrands] = useState<BrandInterface[]>([]);
  const [brandId, setBrandId] = useState<string>("");
  const [brandName, setBrandName] = useState<string>("");

  const getSubCategories = async (id: String, level: Number, setCat: any) => {
    const userJSON = localStorage.getItem("user");
    const user = userJSON ? JSON.parse(userJSON) : null;
    let cate = await API.getData(
      `${API.sellerUrl}getcateaccordingparentcate/${user._id}/${id}/${level}`
    ).then((res) => {
      setCat(res.data);
    });
  };

  useEffect(() => {
    getSubCategories("abc", 1, setlevel1cate);
  }, [productEditData]);

  useEffect(() => {
    if (level1ID !== "") {
      getSubCategories(level1ID, 2, setlevel2cate);
    }
  }, [level1ID]);
  useEffect(() => {
    if (level2ID !== "") {
      getSubCategories(level2ID, 3, setlevel3cate);
    } else if (level2ID === undefined) {
      return;
    }
  }, [level2ID, productEditData]);

  useEffect(() => {
    setProductId(productEditData?._id || "");
    setProductName(productEditData?.name || "");
    setProductDescription(productEditData?.description || "");
    setlevel1ID(productEditData?.level1 || "");
    setlevel2ID(productEditData?.level2 || "");
    setlevel3ID(productEditData?.level3 || "");
    setPrice(productEditData?.minPrice?.price);
    setSellingPrice(productEditData?.minPrice?.sellingPrice);
    setCostPrice(productEditData?.minPrice?.costPrice);
    setGstType(productEditData?.minPrice?.gstType || "none");
    setGst(productEditData?.minPrice?.gst);
    setCostPrice(productEditData?.costPrice);
    if (productEditData?.tax) {
      const cessTax = productEditData.tax.find((tx: Tax) => tx.type === "cess");
      if (cessTax) {
        setCess(cessTax.value);
      }
    }
    setVeg(productEditData?.veg || true);
    setSkuCode(productEditData?.skuCode || "");
    setEanCode(productEditData?.eanCode || "");
    setHsnCode(productEditData?.hsnCode || "");
    setQuantity(productEditData?.quantity || "");
    setProductType(productEditData?.productType || "");
    setQuantityUnit(productEditData?.quantityUnit || "");
    setPackOf(productEditData?.packOf || "");
    setLicenseRequired(productEditData?.licenseRequired || "");
    setLicenseName(productEditData?.licenseName || "");
    setForm(productEditData?.form || "");
    setContainerType(productEditData?.containerType || "");
    setCountryOfOrigin(productEditData?.countryOfOrigin || "");
    setMfgBy(productEditData?.mfgBy || "");
    setMktdBy(productEditData?.mktdBy || "");
    setImportedBy(productEditData?.importedBy || "");
    setProductPhotos(productEditData?.thumbImages);
  }, [productEditData]);

  useEffect(() => {
    const matchingBrand = allBrands.find((b) => b._id === brandId);
    if (matchingBrand) {
      setBrandName(matchingBrand.name);
    }

    setBrandId(productEditData?.brand);
  }, [productEditData, allBrands, brandId]);

  function handleDeleteImage(item: any) {
    setProductPhotos(productPhotos.filter((elm: any) => elm._id !== item._id));
  }
  function handleUpdateImage(e: any) {
    e.stopPropagation();
    setOpen(true);
  }

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        await API.getData(`${API.baseUrl}brand`)
          .then((res) => {
            setAllBrands(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error("Error fetching brand data:", error);
      }
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    if (allBrands.length > 0) {
      const filtered = allBrands.filter((brandItem) =>
        brandItem.name.toLowerCase().includes(brandName.toLowerCase())
      );
      setFilteredBrands(filtered);
    } else {
      setFilteredBrands([]);
    }
  }, [brandName, allBrands]);

  const handleBrandSelect = (selectedBrand: BrandInterface) => {
    setBrand(selectedBrand);
    setBrandName(selectedBrand.name);
    setFilteredBrands([]);
  };

  const addUpdateProduct = async () => {
    if (!level1ID) {
      toast.error("Please select Category");
    } else if (level1ID && !level2ID) {
      toast.error("At least Level 2 category is required to add a product.");
      return;
    } else if (level2ID && level3Cate.length > 0 && !level3ID) {
      toast.error(
        "Level 2 category cannot have products directly. Please select a subcategory."
      );
      return;
    } else if (veg === undefined) {
      toast.error("Select Veg or Non Veg");
      return;
    } else if (!productName) {
      toast.error("Please enter Product name");
      return;
    } else if (!price || !sellingPrice || !gstType || !costPrice) {
      toast.error("Please enter Product price and choose GST type");
      return;
    } else if (+price < +sellingPrice) {
      toast.error("Selling price cannot be greater than MRP");
      return;
    } else if (+price < +costPrice) {
      toast.error("Cost price cannot be greater than MRP");
      return;
    } else if (
      (level3ID && level3Cate.length > 0) ||
      (!level3ID && level3Cate.length === 0)
    ) {
      //handling existing photos
      const previousPhoto =
        productPhotos?.length > 0
          ? productPhotos?.filter((item: any) => item.image)
          : [];
      //handling new photos
      const newPhoto =
        productPhotos?.length > 0
          ? productPhotos
              ?.filter((item: any) => item.file)
              .map((item: any) => item.file)
          : [];

      let productData: any = {
        name: productName,
        description: productDescription,
        level1: level1ID,
        level2: level2ID,
        veg: veg,
        brand: brand,
        sellingPrice: sellingPrice,
        costPrice: costPrice,
        skuCode: skuCode,
        eanCode: eanCode,
        hsnCode: hsnCode,
        productType: productType,
        quantity: quantity,
        quantityUnit: quantityUnit,
        packOf: packOf,
        licenseRequired: licenseRequired,
        licenseName: licenseName,
        form: form,
        containerType: containerType,
        countryOfOrigin: countryOfOrigin,
        mfgBy: mfgBy,
        mktdBy: mktdBy,
        importedBy: importedBy,
        mrp: price,
        minPrice: {
          price,
          costPrice,
          sellingPrice,
          gst,
          gstType,
        },
        productPhoto: newPhoto,
        thumbImages: previousPhoto,
      };

      if (level3ID) {
        productData.level3 = level3ID;
      }

      //calculation for main price
      let finalPrice: any = 0;
      let finalGstValue: any = 0;

      productData.tax = [
        {
          type: "gst",
          value: gst,
        },
        {
          type: "cess",
          value: cess,
        },
      ];
      productData.minPrice = {
        price: price,
        costPrice: costPrice,
        sellingPrice: sellingPrice,
        mainPrice: finalPrice,
        gst: gst,
        gstValue: finalGstValue,
        gstType: gstType,
      };
      const sendData = new FormData();

      setBrandName("");

      if (productEditData.name) {
        try {
          productData._id = productId;
          if (previousPhoto.length === 0) {
            sendData.append(
              "data",
              JSON.stringify({
                ...productData,
              })
            );
            newPhoto.forEach((file) => {
              sendData.append("thumbImages", file);
            });
          } else {
            productData.thumbImages = [...previousPhoto];

            sendData.append(
              "data",
              JSON.stringify({
                ...productData,
              })
            );
            newPhoto.forEach((file) => {
              sendData.append("thumbImages", file);
            });
          }

          const res = await API.putData(
            `${API.baseUrl}product/update/` + productEditData._id,
            sendData
          );
          setBrandName("");
          const findUpdatedProductIndex = productsRecoil.findIndex(
            (item: any) => item._id === res?.data?._id
          );

          let updatedSearchProductIndex = -1; // Default value if index is not found

          if (searchProductsRecoil && searchProductsRecoil.length > 0) {
            updatedSearchProductIndex = searchProductsRecoil.findIndex(
              (item: any) => item._id === res?.data?._id
            );
          }

          if (updatedSearchProductIndex >= 0) {
            const _newSearchProductsRecoil: any = [...productsRecoil];
            _newSearchProductsRecoil[updatedSearchProductIndex] = res.data;
            setSearchProductsRecoil(_newSearchProductsRecoil);
          }
          const _newProductsRecoil: any = [...productsRecoil];
          _newProductsRecoil[findUpdatedProductIndex] = res.data;
          setProductsRecoil(_newProductsRecoil);
          handleClose();
          toast.success("Successfully updated products");
        } catch (err) {
          toast.error("Error occurred while updating Product");
          // handleClose();
        }
      } else {
        try {
          // const res = await API.postData(
          //   `${API.sellerUrl}add-product`,
          //   sendata
          // );
          sendData.append(
            "data",
            JSON.stringify({
              ...productData,
            })
          );
          newPhoto.forEach((file) => {
            sendData.append("thumbImages", file);
          });
          const res = await API.postData(`${API.baseUrl}product/add`, sendData);
          setBrandName("");
          if (productEditData.level2 && productEditData.level2 === catId) {
            let _newProductsRecoil: any = [...productsRecoil];
            _newProductsRecoil = [..._newProductsRecoil, res.data];
            setProductsRecoil(_newProductsRecoil);
          } else if (
            productEditData.level3 &&
            productEditData.level3 === catId
          ) {
            let _newProductsRecoil: any = [...productsRecoil];
            _newProductsRecoil = [..._newProductsRecoil, res.data];
            setProductsRecoil(_newProductsRecoil);
          }
          handleClose();
          toast.success("Successfully added products");
        } catch (e) {
          toast.error("Error occurred while adding Product");
          // handleClose();
        }
      }
    }
  };

  return (
    <>
      <div>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleOpen();
          }}
        >
          Add Product
        </Button>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={showProductModal}
          // onClose={() => {
          //   handleClose();
          // }}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={showProductModal}>
            <Box sx={style}>
              <div className={stylee.wrap}>
                <div className={stylee.upWrap}>
                  <div className={stylee.header}>
                    <h3>Product Name</h3>
                    <input
                      onChange={(e) => setProductName(e.target.value)}
                      className={stylee.product_name}
                      placeholder="Enter Product Name"
                      value={productName}
                      disabled={false} // Disables the input if productName is not empty
                    />

                  </div>

                  <GrFormClose
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                    }}
                    className={stylee.edit_icon}
                  />
                </div>

                <div className={stylee.sub_wrap}>
                  <div className={stylee.category_container}>
                    <h3>Category</h3>
                    <select
                      onChange={(e) => {
                        setlevel1ID(e.target.value);
                        setlevel2ID("");
                        setlevel3ID("");
                        setlevel2cate([]);
                        setlevel3cate([]);
                      }}
                      className={stylee.select}
                      value={level1ID}
                    >
                      <option value="">Select Category</option>
                      {level1Cate.map((cat: any) => {
                        return (
                          <option key={cat?._id} value={cat?._id}>
                            {cat?.name}
                          </option>
                        );
                      })}
                    </select>
                    <h3>Sub Category</h3>
                    <select
                      onChange={(e) => {
                        setlevel2ID(e.target.value);
                        setlevel3ID("");
                        setlevel3cate([]);
                      }}
                      className={stylee.select}
                      value={level2ID}
                    >
                      <option value="">Select Sub Category</option>
                      {level2Cate.map((cat: any) => {
                        return (
                          <option key={cat?._id} value={cat?._id}>
                            {cat?.name}
                          </option>
                        );
                      })}
                    </select>
                    <h3>Sub Category</h3>
                    <select
                      onChange={(e) => setlevel3ID(e.target.value)}
                      className={stylee.select}
                      value={level3ID}
                    >
                      <option value="">Select Sub Category</option>
                      {level3Cate.map((cat: any) => {
                        return (
                          <option key={cat?._id} value={cat?._id}>
                            {cat?.name}
                          </option>
                        );
                      })}
                    </select>
                    <h3>Veg</h3>
                    <select
                      onChange={(e) =>
                        setVeg(e.target.value === "Yes" ? true : false)
                      }
                      className={stylee.select}
                      value={veg === true ? "Yes" : "No"}
                    >
                      <option value={""}>Select</option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </select>
                  </div>
                  <div className={stylee.price_option_container}>
                    <h3>Description</h3>
                    <input
                      onChange={(e) => setProductDescription(e.target.value)}
                      className={stylee.select}
                      placeholder="Enter Description"
                      value={productDescription}
                    />
                    <div>
                      <h3>Price</h3>
                      <div className={stylee.price_box}>
                        <div className={stylee.price_box_sub_cont}>
                          <h4>MRP</h4>
                          <input
                            onChange={(e) => setPrice(+e.target.value)}
                            placeholder="Price"
                            type="number"
                            value={price}
                          />
                        </div>
                        <div className={stylee.price_box_sub_cont}>
                          <h4>Cost Price</h4>
                          <input
                            onChange={(e) => setCostPrice(+e.target.value)}
                            placeholder="Cost Price"
                            type="number"
                            value={costPrice}
                          />
                        </div>
                        <div className={stylee.price_box_sub_cont}>
                          <h4>Selling Price</h4>
                          <input
                            onChange={(e) => setSellingPrice(+e.target.value)}
                            placeholder="Selling Price"
                            type="number"
                            value={sellingPrice}
                          />
                        </div>
                        <div>
                          <h3>Brand</h3>
                          <input
                            type="text"
                            value={brandName}
                            onChange={(e) => {
                              setBrandName(e.target.value);
                            }}
                            placeholder="Enter Brand"
                          />
                          {filteredBrands.length > 0 && brandName && (
                            <div className={stylee.brand_list}>
                              {filteredBrands.map((brandItem) => (
                                <div
                                  key={brandItem._id}
                                  onClick={() => handleBrandSelect(brandItem)}
                                  className={stylee.brand_item}
                                >
                                  {brandItem.name}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div>
                          <h3>EAN Code</h3>
                          <input
                            type="text"
                            value={eanCode}
                            onChange={(e) => setEanCode(e.target.value)}
                            placeholder="Enter EAN Code"
                          />
                        </div>
                        <div>
                          <h3>HSN Code</h3>
                          <input
                            type="text"
                            value={hsnCode}
                            onChange={(e) => setHsnCode(e.target.value)}
                            placeholder="Enter HSN Code"
                          />
                        </div>
                        <div className={stylee.price_box_sub_cont}>
                          <h4>GST Type</h4>
                          <select
                            value={gstType}
                            onChange={(e) => setGstType(e.target.value)}
                          >
                            <option value="none">None</option>
                            <option value="inc">INC</option>
                            <option value="exc">EXC</option>
                          </select>
                        </div>
                        <div className={stylee.price_box_sub_cont}>
                          <h4>GST %</h4>
                          <select
                            value={gst}
                            disabled={gstType === "none"}
                            onChange={(e) => setGst(+e.target.value)}
                          >
                            <option value={0}>None</option>
                            <option value={5}>5</option>
                            <option value={12}>12</option>
                            <option value={18}>18</option>
                            <option value={28}>28</option>
                          </select>
                        </div>
                        <div className={stylee.price_box_sub_cont}>
                          <h4>Cess %</h4>
                          <input
                            type="number"
                            value={cess}
                            onChange={(e) => setCess(+e.target.value)}
                            placeholder="Enter Cess"
                          />
                        </div>
                        {/* <div>
                          <h3>Brand</h3>
                          <input
                            type="text"
                            value={brand}
                            onChange={(e) => setBrand(e.target.value)}
                            placeholder="Enter Brand"
                          />
                        </div> */}
                        <div className={stylee.price_box_sub_cont}>
                          <h4>Product Type</h4>
                          <select
                            value={productType}
                            onChange={(e) => setProductType(e.target.value)}
                          >
                            <option value="regular">Regular</option>
                            <option value="combo">Combo</option>
                            <option value="sample">Sample</option>
                          </select>
                        </div>
                        <div>
                          <h3>SKU Code</h3>
                          <input
                            type="text"
                            value={skuCode}
                            onChange={(e) => setSkuCode(e.target.value)}
                            placeholder="Enter SKU ID"
                          />
                        </div>
                        <div>
                          <h3>Quantity</h3>
                          <input
                            type="number"
                            value={quantity}
                            onChange={(e) => setQuantity(+e.target.value)}
                            placeholder="Enter Quantity"
                          />
                        </div>
                        {/* <div>
                          <h3>Quantity Unit</h3>
                          <input
                            type="text"
                            value={quantityUnit}
                            onChange={(e) => setQuantityUnit(e.target.value)}
                            placeholder="Enter Quantity"
                          />
                        </div> */}

                        {/* <div>
                          <h3>License Required</h3>
                          <select
                            value={licenseRequired ? "Yes" : "No"}
                            onChange={(e) => setLicenseRequired(e.target.value === "Yes")}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                        <div>
                          <h3>License Name</h3>
                          <input
                            type="text"
                            value={licenseName}
                            onChange={(e) => setLicenseName(e.target.value)}
                            placeholder="Enter License Name"
                          />
                        </div>
                        <div>
                          <h3>Pack_of</h3>
                          <input
                            type="number"
                            value={packOf}
                            onChange={(e) => setPackOf(+e.target.value)}
                            placeholder="Enter Pack Of"
                          />
                        </div>
                        <div>
                          <h3>Form Type</h3>
                          <input
                            type="text"
                            value={form}
                            onChange={(e) => setForm(e.target.value)}
                            placeholder="Enter From Type like: Liquid, Cream, Balm, Capsule"
                          />
                        </div>
                        <div>
                          <h3>Container Type</h3>
                          <input
                            type="text"
                            value={containerType}
                            onChange={(e) => setContainerType(e.target.value)}
                            placeholder="Enter Container Type like: Bottle, Box, Inhaler, Tube, Sachet"
                          />
                        </div>
                        <div>
                          <h3>Country Of Origin</h3>
                          <input
                            type="text"
                            value={countryOfOrigin}
                            onChange={(e) => setCountryOfOrigin(e.target.value)}
                            placeholder="Enter Country Or Origin"
                          />
                        </div> */}
                        {/* <div>
                          <h3>MFG By</h3>
                          <input
                            type="text"
                            value={mfgBy}
                            onChange={(e) => setMfgBy(e.target.value)}
                            placeholder="Enter Manufactured By With Address"
                          />
                        </div>
                        <div>
                          <h3>MKTD By</h3>
                          <input
                            type="text"
                            value={mktdBy}
                            onChange={(e) => setMktdBy(e.target.value)}
                            placeholder="Enter Marketed By With Address"
                          />
                        </div>
                        <div>
                          <h3>If Imoprted By</h3>
                          <input
                            type="text"
                            value={importedBy}
                            onChange={(e) => setImportedBy(e.target.value)}
                            placeholder="Enter Imported By"
                          />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={stylee.image_head}>
                    <h3>Images</h3>
                    <Button
                      onClick={handleUpdateImage}
                      // className={stylee.primary_btn}
                    >
                      Add/Update Image
                    </Button>
                  </div>
                  <div className={stylee.image_container}>
                    {productPhotos?.length ? (
                      productPhotos?.map((photo: any) => {
                        return (
                          <div
                            key={photo?.image || photo?.thumb}
                            className={stylee.image_box}
                          >
                            <GrFormClose
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteImage(photo);
                              }}
                              className={stylee.image_cross}
                            />
                            <img
                              onClick={(e) => {
                                e.stopPropagation();
                                window.open(
                                  photo?.image || photo?.thumb,
                                  "_blank"
                                );
                              }}
                              width="65px"
                              height="65px"
                              key={photo?.thumb}
                              src={photo?.thumb}
                              alt="Item"
                            />
                          </div>
                        );
                      })
                    ) : (
                      <>
                        {dummyImageProduct.map((item) => (
                          <BsFillImageFill
                            onClick={handleUpdateImage}
                            className={stylee.dummy_img}
                            key={item}
                          />
                        ))}
                      </>
                    )}
                  </div>
                  <div className={stylee.submit_btn}>
                    <button
                      onClick={addUpdateProduct}
                      className={stylee.primary_btn}
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>

      <UploadImageModal
        productPhotos={productPhotos}
        setProductPhotos={setProductPhotos}
        dummyImageProduct={dummyImageProduct}
        setOpen={setOpen}
        open={open}
      />
    </>
  );
}
